@charset "UTF-8";
/*-----------------------------------------------------------
    Theme Name: Elomoas
    Theme URI: http://uitheme.net/elomoas/
    Description: Online Course and LMS HTML Template
    Version: 1.0.0
-------------------------------------------------------------*/
/*-----------------------------------------------------------
    Table of Content: 


    2. Bootstrap CSS
    1. Abstract
    3. Base
    4. Component
    5. Pages
    7. Header
    8. Footer



*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import "./../vendor/owl-carousel/css/owl.carousel.min.css";
@import "./../vendor/owl-carousel/css/owl.theme.default.css";
.theme-dark {
  background-color: #1a2236;
}
.theme-dark .app-content {
  background-color: #1a2236;
}
.theme-dark .app-content:before {
  background-color: rgba(0, 0, 0, 0.9);
}
.theme-dark .card {
  background-color: #293145 !important;
}
.theme-dark .app-footer {
  background-color: #293145 !important;
  border-top: 0;
}
.theme-dark .app-footer i {
  color: #fff;
}
.theme-dark .app-header .logo svg path {
  fill: #fff;
}
.theme-dark .app-header .menu-icon i {
  color: #fff;
}
.theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6, .theme-dark .h1, .theme-dark .h2, .theme-dark .h3, .theme-dark .h4, .theme-dark .h5, .theme-dark .h6 {
  color: #fff !important;
}

.bg-facebook {
  background-color: #0d66ff !important;
}

.bg-messenger {
  background-color: #55acee !important;
}

.bg-twiiter {
  background-color: #3b5999 !important;
}

.bg-linkedin {
  background-color: #0077B5 !important;
}

.bg-skype {
  background-color: #00AFF0 !important;
}

.bg-pinterest {
  background-color: #bd081c !important;
}

.bg-instagram {
  background-color: #e4405f !important;
}

.bg-flicker {
  background-color: #ff0084 !important;
}

.bg-whatsup {
  background-color: #25D366 !important;
}

.bg-tumblr {
  background-color: #34465d !important;
}

.bg-youtube {
  background-color: #cd201f !important;
}

.bg-black {
  background-color: #000;
}

.text-facebook {
  color: #0d66ff !important;
}

.text-messenger {
  color: #55acee !important;
}

.text-twiiter {
  color: #3b5999 !important;
}

.text-linkedin {
  color: #0077B5 !important;
}

.text-skype {
  color: #00AFF0 !important;
}

.text-pinterest {
  color: #bd081c !important;
}

.text-instagram {
  color: #e4405f !important;
}

.text-current {
  color: var(--theme-color) !important;
}

.text-red {
  color: red !important;
}

.text-ornage {
  color: #ff9500 !important;
}

.text-black {
  color: #000 !important;
}

.text-cyan {
  color: #00a9a8 !important;
}

.text-yellow {
  color: #ffde00 !important;
}

.bg-black-08 {
  background: rgba(0, 0, 0, 0.8);
}

.bg-current {
  background-color: #05f !important;
}

.bg-current-shade {
  background-color: #09f !important;
}

.bg-primary-gradiant {
  background: linear-gradient(135deg, #05f, #09f) !important;
}

.bg-gold-gradiant {
  background: linear-gradient(to right, #f2994a, #f2c94c) !important;
}

.bg-red-gradiant {
  background: linear-gradient(to right, #e44d26, #f16529) !important;
}

.bg-mini-gradiant {
  background: linear-gradient(to right, #ee0979, #ff6a00) !important;
}

.bg-blue-gradiant {
  background: linear-gradient(to right, #0575e6, #021b79) !important;
}

.bg-grey {
  background-color: #eee !important;
}

.bg-lightblue {
  background-color: #e8f1fa !important;
}

.bg-lightgreen {
  background-color: #f2faf6 !important;
}

.bg-greyblue {
  background-color: #f6f7fc !important;
}

.bg-greylight {
  background-color: #f5f5f5 !important;
}

.bg-lightgrey {
  background-color: #f9f9f9 !important;
}

.bg-lightbrown {
  background-color: #fdfbf4 !important;
}

.bg-orange {
  background-color: #ff9500 !important;
}

.bg-cyan {
  background-color: #00a9a8 !important;
}

.bg-yellow {
  background-color: #ffde00 !important;
}

.bg-blur {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.3);
}

.bg-after-fluid {
  position: relative;
}
.bg-after-fluid:after {
  top: 0;
  content: "";
  border-radius: 10px;
  background: #ffe2ee;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 5%;
}
.bg-after-fluid .container {
  position: relative;
  z-index: 2;
}

.bg-gradiant-bottom:after {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 60%, rgba(0, 0, 0, 0.9) 100%);
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.bg-gradiant-top:before {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.01) 60%, rgba(0, 0, 0, 0.6) 100%);
  width: 100%;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bg-lightblue-after {
  position: relative;
}
.bg-lightblue-after .container {
  position: relative;
  z-index: 3;
}
.bg-lightblue-after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: #fef6e7 !important;
}

.pattern-layer {
  position: relative;
}
.pattern-layer:after {
  content: "";
  position: absolute;
  top: -300px;
  right: -10%;
  height: 100%;
  z-index: 1;
  width: 120%;
  height: 100%;
  background-image: url(../images/layer-pattenr-bg.png);
  background-repeat: repeat-y;
  background-size: contain;
}
.pattern-layer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.8);
}
.pattern-layer .layer-after {
  position: relative;
  z-index: 3;
}

.color-theme-red {
  --theme-color: #f32323;
  --theme-color-rgb: 255, 59, 48;
  --theme-color-shade: #fb9f81 ;
  --theme-color-tint: #ff6259;
}

.color-theme-green {
  --theme-color: #4cd964;
  --theme-color-rgb: 76, 217, 100;
  --theme-color-shade: #2cd048;
  --theme-color-tint: #6ee081;
}

.color-theme-blue {
  --theme-color: #05f;
  --theme-color-rgb: 33, 150, 243;
  --theme-color-shade: #09f;
  --theme-color-tint: #1E74FD;
}

.color-theme-deepblue {
  --theme-color: #0f36c0;
  --theme-color-rgb: 33, 150, 243;
  --theme-color-shade: #103ace;
  --theme-color-tint: #1E74FD;
}

.color-theme-pink {
  --theme-color: #ff2d55;
  --theme-color-rgb: 255, 45, 85;
  --theme-color-shade: #ff0434;
  --theme-color-tint: #ff5676;
}

.color-theme-yellow {
  --theme-color: #fbaf03;
  --theme-color-rgb: 255, 204, 0;
  --theme-color-shade: #d6ab00;
  --theme-color-tint: #ffd429;
}

.color-theme-orange {
  --theme-color: #ff9500;
  --theme-color-rgb: 255, 149, 0;
  --theme-color-shade: #d67d00;
  --theme-color-tint: #ffa629;
}

.color-theme-purple {
  --theme-color: #9c27b0;
  --theme-color-rgb: 156, 39, 176;
  --theme-color-shade: #7e208f;
  --theme-color-tint: #b92fd1;
}

.color-theme-deeppurple {
  --theme-color: #673ab7;
  --theme-color-rgb: 103, 58, 183;
  --theme-color-shade: #563098;
  --theme-color-tint: #7c52c8;
}

.color-theme-lightblue {
  --theme-color: #346e93;
  --theme-color-rgb: 90, 200, 250;
  --theme-color-shade: #7ac1ed;
  --theme-color-tint: #82d5fb;
}

.color-theme-teal {
  --theme-color: #00bfad;
  --theme-color-rgb: 0, 150, 136;
  --theme-color-shade: #00bfad;
  --theme-color-tint: #00bfad;
}

.color-theme-lime {
  --theme-color: #cddc39;
  --theme-color-rgb: 205, 220, 57;
  --theme-color-shade: #bac923;
  --theme-color-tint: #d6e25c;
}

.color-theme-deeporange {
  --theme-color: #ff6b22;
  --theme-color-rgb: 255, 107, 34;
  --theme-color-shade: #f85200;
  --theme-color-tint: #ff864b;
}

.color-theme-gray {
  --theme-color: #8e8e93;
  --theme-color-rgb: 142, 142, 147;
  --theme-color-shade: #79797f;
  --theme-color-tint: #a3a3a7;
}

.color-theme-white {
  --theme-color: #ffffff;
  --theme-color-rgb: 255, 255, 255;
  --theme-color-shade: #ebebeb;
  --theme-color-tint: #ffffff;
}

.color-theme-black {
  --theme-color: #000000;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #000000;
  --theme-color-tint: #141414;
}

.color-theme-brown {
  --theme-color: #D2691E;
  --theme-color-rgb: #D2691E;
  --theme-color-shade: #D2691E;
  --theme-color-tint: #D2691E;
}

.color-theme-darkgreen {
  --theme-color: #1c891f;
  --theme-color-rgb: 142, 142, 147;
  --theme-color-shade: #79797f;
  --theme-color-tint: #a3a3a7;
}

.color-theme-cayan {
  --theme-color: #7ac1ed;
  --theme-color-rgb: 255, 255, 255;
  --theme-color-shade: #6ae2da;
  --theme-color-tint: #6ae2da;
}

.color-theme-darkorchid {
  --theme-color: #9932cc;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #000000;
  --theme-color-tint: #141414;
}

.color-theme-black {
  --theme-color: #000;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #000;
  --theme-color-tint: #000;
}

.color-theme-cadetblue {
  --theme-color: #5f9ea0;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #5f9ea0;
  --theme-color-tint: #5f9ea0;
}

.color-theme-deeppink {
  --theme-color: #FFC0CB;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #FFC0CB;
  --theme-color-tint: #FFC0CB;
}

.w10 {
  width: 12px !important;
}

.w15 {
  width: 15px !important;
}

.w20 {
  width: 18px !important;
}

.w25 {
  width: 25px !important;
}

.w30 {
  width: 30px !important;
}

.w35 {
  width: 35px !important;
}

.w40 {
  width: 40px !important;
}

.w45 {
  width: 45px !important;
}

.w50 {
  width: 50px !important;
}

.w60 {
  width: 60px !important;
}

.w65 {
  width: 65px !important;
}

.w75 {
  width: 75px !important;
}

.w90 {
  width: 90px !important;
}

.w100 {
  width: 100px !important;
}

.w125 {
  width: 125px !important;
}

.w140 {
  width: 140px !important;
}

.w150 {
  width: 150px !important;
}

.w175 {
  width: 175px !important;
}

.w200 {
  width: 200px !important;
}

.w250 {
  width: 250px !important;
}

.w300 {
  width: 300px !important;
}

.w350 {
  width: 350px !important;
}

.w400 {
  width: 400px !important;
}

.w__12 {
  width: 12.1% !important;
  flex: 0 0 30% !important;
  max-width: 30% !important;
}

.w__30 {
  width: 30% !important;
  flex: 0 0 30% !important;
  max-width: 30% !important;
}

.w__60 {
  width: 60% !important;
  flex: 0 0 60% !important;
  max-width: 60% !important;
}

.w__70 {
  width: 70% !important;
  flex: 0 0 70% !important;
  max-width: 70% !important;
}

.w__48 {
  width: 49% !important;
  flex: 0 0 49% !important;
  max-width: 49% !important;
}

.h5 {
  height: 5px !important;
}

.h10 {
  height: 7px !important;
}

.h75 {
  height: 75px !important;
}

.h90 {
  height: 90px !important;
}

.h100 {
  height: 100px !important;
}

.h125 {
  height: 125px !important;
}

.h150 {
  height: 150px !important;
}

.h175 {
  height: 175px !important;
}

.h200 {
  height: 200px !important;
}

.h240 {
  height: 242px !important;
}

.h290 {
  height: 290px !important;
}

.h300 {
  height: 300px !important;
}

.h361 {
  height: 361px !important;
}

.h400 {
  height: 400px !important;
}

.h450 {
  height: 450px !important;
}

.h500 {
  height: 500px !important;
}

.h600 {
  height: 600px !important;
}

.btn-round-xss {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center;
}
.btn-round-xss i {
  line-height: 25px !important;
}

.btn-round-xs {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: inline-block;
  line-height: 20px !important;
  text-align: center;
}
.btn-round-xs i {
  line-height: 20px !important;
}

.btn-round-sm {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: inline-block;
  line-height: 35px !important;
  text-align: center;
}
.btn-round-sm i {
  line-height: 35px !important;
}

.btn-round-md {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  display: inline-block;
  line-height: 45px !important;
  text-align: center;
}
.btn-round-md i {
  line-height: 45px;
}

.btn-round-lg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
}
.btn-round-lg i {
  line-height: 50px;
}

.btn-round-xl {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
}
.btn-round-xl i {
  line-height: 60px;
}
.btn-round-xl img {
  line-height: 60px;
}

.btn-round-xxl {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  display: inline-block;
  line-height: 70px;
  text-align: center;
}
.btn-round-xxl i {
  line-height: 70px;
}

.btn-round-xxxl {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: inline-block;
  line-height: 80px;
  text-align: center;
}
.btn-round-xxxl i {
  line-height: 80px;
}

.btn-round-xxxxl {
  width: 110px;
  height: 110px;
  border-radius: 110px;
  display: inline-block;
  line-height: 110px;
  text-align: center;
}
.btn-round-xxxxl i {
  line-height: 110px;
}

.z-index-1 {
  z-index: 2;
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

.ls-0 {
  letter-spacing: -0.4px;
}

.ls-1 {
  letter-spacing: 0.4px;
}

.ls-2 {
  letter-spacing: 0.6px;
}

.ls-3 {
  letter-spacing: 1px;
}

.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.4;
}

.lh-4 {
  line-height: 1.6;
}

.lh-5 {
  line-height: 2;
}

.lh-16 {
  line-height: 16px !important;
}

.lh-24 {
  line-height: 24px !important;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-30 {
  line-height: 30px !important;
}

.lh-32 {
  line-height: 32px;
}

.lh-34 {
  line-height: 34px;
}

.lh-38 {
  line-height: 38px;
}

.font-xsssss {
  font-size: 10px !important;
}

.font-xssss {
  font-size: 12px !important;
}

.font-xsss {
  font-size: 14px !important;
}

.font-xss {
  font-size: 16px !important;
}

.font-xs {
  font-size: 18px !important;
}

.font-sm {
  font-size: 20px !important;
}

.font-md {
  font-size: 22px !important;
}

.font-lg {
  font-size: 25px !important;
}

.font-xl {
  font-size: 28px !important;
}

.font-xxl {
  font-size: 32px !important;
}

.display1-size {
  font-size: 36px !important;
}

.display2-size {
  font-size: 44px !important;
}

.display3-size {
  font-size: 50px !important;
}

.display4-size {
  font-size: 60px !important;
}

.display5-size {
  font-size: 80px !important;
}

.shadow-md {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.shadow-xs {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
}

.shadow-xss {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05) !important;
}

.border-size-sm {
  border-width: 1px;
}

.border-size-md {
  border-width: 2px !important;
}

.border-size-lg {
  border-width: 3px !important;
}

.border-light {
  border: 1px #eee solid;
}

.border-light-md {
  border: 2px #ddd solid;
}

.border-right-light {
  border-right: 1px #eee solid;
}

.border-bottom-light {
  border-bottom: 1px #eee solid;
}

.border-bottom-dark {
  border-bottom: 2px #333 solid;
}

.border-bottom-blue {
  border-bottom: 1px #1254a1 solid;
}

.border-dashed {
  border: 2px #ddd dashed !important;
}

.border-black {
  border: 2px #000 dashed !important;
}

.border-top-lg {
  border-top: 3px solid #000;
}

.bg-image-cover {
  background-size: cover;
}

.bg-image-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-image-center {
  background-position: center center;
}

.bg-image-topcenter {
  background-position: top 30px center;
}

.bg-image-bottomcenter {
  background-position: bottom center;
}

.bg-image-right {
  background-position: right -60px top 0px;
}

.bg-size-contain {
  background-size: contain;
}

.bg-bottom-right {
  background-position: bottom right;
}

.bg-top-right {
  background-position: top right;
}

.bg-top-left {
  background-position: top left;
}

.bg-size-half {
  background-size: 40%;
}

.bg-size-quater {
  background-size: 70%;
}

.bg-top-right-2 {
  background-position: top 50px right 20px;
}

.bg-top-100-right {
  background-position: top 50px right 50px;
}

.bg-pattern {
  background: repeating-linear-gradient(45deg, #ffffff, #ffffff 6px, #ddd 6px, #ddd 12px);
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0 !important;
}

.top--15 {
  top: -15px !important;
  z-index: 2;
}

.top--10 {
  top: -10px !important;
  z-index: 2;
}

.top-5 {
  top: 3px;
}

.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-15 {
  left: 15px;
}

.left--50 {
  left: 50%;
}

.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0;
}

.right-15 {
  right: 15px;
}

.right--10 {
  right: -10px;
}

.pt-7 {
  padding-top: 75px;
}

.pb-7 {
  padding-bottom: 75px;
}

.pt-10 {
  padding-top: 125px;
}

.pb-10 {
  padding-bottom: 125px;
}

.p-150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-7 {
  padding-left: 70px;
}

.mt--1 {
  margin-top: -0.4rem !important;
}

.mt--6 {
  margin-top: -4.2rem !important;
}

.round-lg-btn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
}

.rounded-xxl {
  border-radius: 15px !important;
}

.rounded-xl {
  border-radius: 30px !important;
}

.list-style-disc li {
  list-style-type: disc;
  margin-bottom: 5px;
}

.avatar.w90 {
  overflow: hidden;
}

:root {
  --f7-theme-color: #132977;
}

.theme-full {
  background-color: #ddd !important;
}

.theme-full .main-wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.theme-dark {
  background-color: #132977;
}
.theme-dark .middle-sidebar-header,
.theme-dark .navigation {
  background-color: #293145 !important;
  box-shadow: none !important;
}
.theme-dark .navigation .nav-content ul li:not(.logo) a span {
  color: #ddd;
}
.theme-dark .navigation .nav-top a i {
  background-color: transparent !important;
}
.theme-dark .scroll-bar::-webkit-scrollbar,
.theme-dark .scroll-bar::-webkit-scrollbar-track {
  background-color: #1a2236 !important;
}
.theme-dark .main-content .middle-sidebar-header .header-menu:before,
.theme-dark .main-content .middle-sidebar-header .header-menu:after {
  background-color: #fff;
}
.theme-dark .main-content .middle-sidebar-header .menu-search-icon i {
  color: #fff !important;
}
.theme-dark .main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content .card {
  border: 0 !important;
}
.theme-dark .main-content .middle-sidebar-header {
  border-bottom: 0;
}
.theme-dark .modal-popup-chat .modal-popup-wrap .modal-popup-header,
.theme-dark .modal-popup-chat .modal-popup-wrap .modal-popup-footer {
  border: 0 !important;
}
.theme-dark .input-file + .js-labelFile.btn-tertiary,
.theme-dark .modal-popup-chat .modal-popup-wrap,
.theme-dark .theme-dark-bg {
  background-color: #1a2236 !important;
}
.theme-dark ul.list-inline .list-inline-item.border-bottom {
  border-bottom: 0 !important;
}
.theme-dark .form-control {
  background-color: #333 !important;
  border-color: var(--theme-color);
}
.theme-dark .menu-dropdown:after {
  border-color: transparent transparent #1a2236 transparent !important;
}
.theme-dark .bg-transparent-card {
  background: transparent !important;
}
.theme-dark .menu-dropdown,
.theme-dark .main-content {
  background-color: #1a2236 !important;
}
.theme-dark .form-group.bg-white {
  border: 0 !important;
  background-color: #1a2236 !important;
}
.theme-dark .main-content .left-sidebar .left-sidebar-content .left-sidebar-header,
.theme-dark .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom,
.theme-dark .main-content .left-sidebar .left-sidebar-content {
  background-color: #293145 !important;
}
.theme-dark .apexcharts-datalabel-value {
  fill: #eee;
}
.theme-dark .form-group label,
.theme-dark .text-dark {
  color: #eee !important;
}
.theme-dark .member-ul li.active a b,
.theme-dark .price-wrap .left-price h2,
.theme-dark .form-div h2,
.theme-dark .slide-wrapper h2 {
  color: #eee;
}
.theme-dark .header-wrapper .search-div {
  border: 1px #555 solid;
}
.theme-dark .header-wrapper .nav-menu li a {
  color: #ddd !important;
}
.theme-dark .search-wrapper,
.theme-dark .accordion .card .card-body,
.theme-dark .accordion .card .card-header,
.theme-dark .form-div {
  background-color: #293145;
}
.theme-dark .header-wrapper .logo {
  color: lightsteelblue;
}
.theme-dark .card p {
  color: #ddd !important;
}
.theme-dark .accordion .card button:after,
.theme-dark .accordion .card button,
.theme-dark .price-wrap .middle-price h6,
.theme-dark .form-div p,
.theme-dark .form-div label p,
.theme-dark .form-div .sign-up-text {
  color: #ddd;
}
.theme-dark .form-div label p a,
.theme-dark .form-div .sign-up-text a {
  color: #fff;
  margin-left: 5px;
}
.theme-dark .navbar-toggler {
  color: white;
  border-color: white;
}
.theme-dark .accordion .card button.small-text a,
.theme-dark .accordion .card button.small-text {
  color: #fff;
}
.theme-dark .card-header {
  border-bottom: 0 solid #000;
}
.theme-dark .dark-bg-transparent {
  background-color: transparent !important;
}
.theme-dark .dark-bg {
  background-color: #1a2236 !important;
}
.theme-dark .nav-tabs li a.active {
  border-bottom: 2px #ddd solid !important;
  color: #fff !important;
}
.theme-dark .form-group label.text-dark-color,
.theme-dark .text-dark-color {
  color: #111 !important;
}
.theme-dark .list-group .list-group-item {
  border-bottom: 0 solid #000;
}
.theme-dark .course-slider {
  background-color: #000 !important;
}

.theme-dark .header-wrapper .logo .light {
  display: none;
}

.theme-dark .header-wrapper .logo .dark {
  display: inline-block;
}

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1E74FD;
  --secondary: #673BB7;
  --success: #10d876;
  --info: #2754e6;
  --warning: #FE9431;
  --danger: #E50202;
  --light: #f7f7f7;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1E74FD;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0250cd;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #AEAED5;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #111;
}

h1, .h1 {
  font-size: 1.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
}

h3, .h3 {
  font-size: 1.125rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 2.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 3.125rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1199px;
  }
}

.container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1199px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1400) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #515184;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #E1E1F0;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #E1E1F0;
}
.table tbody + tbody {
  border-top: 2px solid #E1E1F0;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #E1E1F0;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #E1E1F0;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #515184;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0d8fe;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8ab7fe;
}

.table-hover .table-primary:hover {
  background-color: #a7c8fe;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a7c8fe;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4c8eb;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b099da;
}

.table-hover .table-secondary:hover {
  background-color: #c5b5e4;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c5b5e4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bcf4d9;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #83ebb8;
}

.table-hover .table-success:hover {
  background-color: #a6f0cd;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a6f0cd;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c3cff8;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8fa6f2;
}

.table-hover .table-info:hover {
  background-color: #acbdf5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #acbdf5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe1c5;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fec794;
}

.table-hover .table-warning:hover {
  background-color: #ffd4ac;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffd4ac;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8b8b8;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f17b7b;
}

.table-hover .table-danger:hover {
  background-color: #f6a1a1;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f6a1a1;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfbfb;
}

.table-hover .table-light:hover {
  background-color: #f0f0f0;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f0f0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #E1E1F0;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #9cc2fe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #515184;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #AEAED5;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #10d876;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(16, 216, 118, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #10d876;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #10d876;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #10d876;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #10d876;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #10d876;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #2cef8f;
  background-color: #2cef8f;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #10d876;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #10d876;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #10d876;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #E50202;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(229, 2, 2, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E50202;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E50202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #E50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E50202;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E50202' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #E50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E50202;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E50202;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #E50202;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fd1d1d;
  background-color: #fd1d1d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E50202;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E50202;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E50202;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #515184;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #515184;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #1E74FD;
  border-color: #1E74FD;
}
.btn-primary:hover {
  color: #fff;
  background-color: #025ff3;
  border-color: #025ae6;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #025ff3;
  border-color: #025ae6;
  box-shadow: 0 0 0 0rem rgba(64, 137, 253, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1E74FD;
  border-color: #1E74FD;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #025ae6;
  border-color: #0255d9;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(64, 137, 253, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #673BB7;
  border-color: #673BB7;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #57329a;
  border-color: #512f90;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #57329a;
  border-color: #512f90;
  box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #673BB7;
  border-color: #673BB7;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #512f90;
  border-color: #4c2b87;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(126, 88, 194, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-success:hover {
  color: #fff;
  background-color: #0db463;
  border-color: #0ca95c;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #0db463;
  border-color: #0ca95c;
  box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0ca95c;
  border-color: #0c9d56;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(52, 222, 139, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-info:hover {
  color: #fff;
  background-color: #1843cf;
  border-color: #173fc3;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #1843cf;
  border-color: #173fc3;
  box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #173fc3;
  border-color: #153cb8;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(71, 110, 234, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #FE9431;
  border-color: #FE9431;
}
.btn-warning:hover {
  color: #212529;
  background-color: #fe800b;
  border-color: #fb7a01;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #fe800b;
  border-color: #fb7a01;
  box-shadow: 0 0 0 0rem rgba(221, 131, 48, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #FE9431;
  border-color: #FE9431;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #fb7a01;
  border-color: #ee7401;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(221, 131, 48, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #E50202;
  border-color: #E50202;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bf0202;
  border-color: #b20202;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #bf0202;
  border-color: #b20202;
  box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #E50202;
  border-color: #E50202;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b20202;
  border-color: #a60101;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(233, 40, 40, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-light:hover {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #dedede;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e4e4e4;
  border-color: #dedede;
  box-shadow: 0 0 0 0rem rgba(215, 216, 216, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dedede;
  border-color: #d7d7d7;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(215, 216, 216, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #1E74FD;
  border-color: #1E74FD;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #1E74FD;
  border-color: #1E74FD;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1E74FD;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1E74FD;
  border-color: #1E74FD;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.5);
}

.btn-outline-secondary {
  color: #673BB7;
  border-color: #673BB7;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #673BB7;
  border-color: #673BB7;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #673BB7;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #673BB7;
  border-color: #673BB7;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}

.btn-outline-success {
  color: #10d876;
  border-color: #10d876;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #10d876;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #10d876;
  border-color: #10d876;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}

.btn-outline-info {
  color: #2754e6;
  border-color: #2754e6;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #2754e6;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}

.btn-outline-warning {
  color: #FE9431;
  border-color: #FE9431;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #FE9431;
  border-color: #FE9431;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #FE9431;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #FE9431;
  border-color: #FE9431;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}

.btn-outline-danger {
  color: #E50202;
  border-color: #E50202;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #E50202;
  border-color: #E50202;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #E50202;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #E50202;
  border-color: #E50202;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}

.btn-outline-light {
  color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0rem rgba(247, 247, 247, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f7f7f7;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(247, 247, 247, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #1E74FD;
  text-decoration: none;
}
.btn-link:hover {
  color: #0250cd;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #515184;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1E74FD;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.35rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.85rem;
  height: 1.675rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--theme-color);
  background-color: var(--theme-color);
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #9cc2fe;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #cfe1ff;
  border-color: #cfe1ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: -0.175rem;
  left: -2.35rem;
  display: block;
  width: 1.85rem;
  height: 1.85rem;
  pointer-events: none;
  content: "";
  background-color: #ddd;
  border: #fff solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: -0.175rem;
  left: -2.35rem;
  display: block;
  width: 1.85rem;
  height: 1.85rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1E74FD;
  background-color: #1E74FD;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-switch {
  padding-left: 3.7375rem;
}
.custom-switch .custom-control-label::before {
  left: -3.7375rem;
  width: 3.2375rem;
  pointer-events: all;
  border-radius: 0.925rem;
}
.custom-switch .custom-control-label::after {
  top: calc(-0.175rem + 2px);
  left: calc(-3.7375rem + 2px);
  width: calc(1.85rem - 4px);
  height: calc(1.85rem - 4px);
  background-color: #fff;
  border-radius: 0.925rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ddd;
  transform: translateX(1.3875rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #9cc2fe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #9cc2fe;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(30, 116, 253, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1E74FD;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #cfe1ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1E74FD;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #cfe1ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0rem;
  margin-left: 0rem;
  background-color: #1E74FD;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #cfe1ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1E74FD;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1399.98) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1400) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #1E74FD;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0250cd;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1E74FD;
  border-color: #1E74FD;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #1E74FD;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #025ae6;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(30, 116, 253, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #673BB7;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #512f90;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(103, 59, 183, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #10d876;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #0ca95c;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(16, 216, 118, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #2754e6;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #173fc3;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(39, 84, 230, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #FE9431;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #fb7a01;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(254, 148, 49, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #E50202;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #b20202;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(229, 2, 2, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f7f7f7;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dedede;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(247, 247, 247, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #103c84;
  background-color: #d2e3ff;
  border-color: #c0d8fe;
}
.alert-primary hr {
  border-top-color: #a7c8fe;
}
.alert-primary .alert-link {
  color: #0a2757;
}

.alert-secondary {
  color: #361f5f;
  background-color: #e1d8f1;
  border-color: #d4c8eb;
}
.alert-secondary hr {
  border-top-color: #c5b5e4;
}
.alert-secondary .alert-link {
  color: #201239;
}

.alert-success {
  color: #08703d;
  background-color: #cff7e4;
  border-color: #bcf4d9;
}
.alert-success hr {
  border-top-color: #a6f0cd;
}
.alert-success .alert-link {
  color: #054023;
}

.alert-info {
  color: #142c78;
  background-color: #d4ddfa;
  border-color: #c3cff8;
}
.alert-info hr {
  border-top-color: #acbdf5;
}
.alert-info .alert-link {
  color: #0d1c4c;
}

.alert-warning {
  color: #844d19;
  background-color: #ffead6;
  border-color: #ffe1c5;
}
.alert-warning hr {
  border-top-color: #ffd4ac;
}
.alert-warning .alert-link {
  color: #593411;
}

.alert-danger {
  color: #770101;
  background-color: #facccc;
  border-color: #f8b8b8;
}
.alert-danger hr {
  border-top-color: #f6a1a1;
}
.alert-danger .alert-link {
  color: #440101;
}

.alert-light {
  color: gray;
  background-color: #fdfdfd;
  border-color: #fdfdfd;
}
.alert-light hr {
  border-top-color: #f0f0f0;
}
.alert-light .alert-link {
  color: #676767;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1E74FD;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #515184;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1E74FD;
  border-color: #1E74FD;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #103c84;
  background-color: #c0d8fe;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #103c84;
  background-color: #a7c8fe;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #103c84;
  border-color: #103c84;
}

.list-group-item-secondary {
  color: #361f5f;
  background-color: #d4c8eb;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #361f5f;
  background-color: #c5b5e4;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #361f5f;
  border-color: #361f5f;
}

.list-group-item-success {
  color: #08703d;
  background-color: #bcf4d9;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #08703d;
  background-color: #a6f0cd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #08703d;
  border-color: #08703d;
}

.list-group-item-info {
  color: #142c78;
  background-color: #c3cff8;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #142c78;
  background-color: #acbdf5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #142c78;
  border-color: #142c78;
}

.list-group-item-warning {
  color: #844d19;
  background-color: #ffe1c5;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #844d19;
  background-color: #ffd4ac;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #844d19;
  border-color: #844d19;
}

.list-group-item-danger {
  color: #770101;
  background-color: #f8b8b8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #770101;
  background-color: #f6a1a1;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #770101;
  border-color: #770101;
}

.list-group-item-light {
  color: gray;
  background-color: #fdfdfd;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: gray;
  background-color: #f0f0f0;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: gray;
  border-color: gray;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E1E1F0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E1E1F0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #111;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #515184;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #1E74FD !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #025ae6 !important;
}

.bg-secondary {
  background-color: #673BB7 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #512f90 !important;
}

.bg-success {
  background-color: #10d876 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0ca95c !important;
}

.bg-info {
  background-color: #2754e6 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #173fc3 !important;
}

.bg-warning {
  background-color: #FE9431 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fb7a01 !important;
}

.bg-danger {
  background-color: #E50202 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #b20202 !important;
}

.bg-light {
  background-color: #f7f7f7 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dedede !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #E1E1F0 !important;
}

.border-top {
  border-top: 1px solid #E1E1F0 !important;
}

.border-right {
  border-right: 1px solid #E1E1F0 !important;
}

.border-bottom {
  border-bottom: 1px solid #E1E1F0 !important;
}

.border-left {
  border-left: 1px solid #E1E1F0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1E74FD !important;
}

.border-secondary {
  border-color: #673BB7 !important;
}

.border-success {
  border-color: #10d876 !important;
}

.border-info {
  border-color: #2754e6 !important;
}

.border-warning {
  border-color: #FE9431 !important;
}

.border-danger {
  border-color: #E50202 !important;
}

.border-light {
  border-color: #f7f7f7 !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #1E74FD !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0250cd !important;
}

.text-secondary {
  color: #673BB7 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #46287d !important;
}

.text-success {
  color: #10d876 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #0b914f !important;
}

.text-info {
  color: #2754e6 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #1438ad !important;
}

.text-warning {
  color: #FE9431 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #e16d01 !important;
}

.text-danger {
  color: #E50202 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #990101 !important;
}

.text-light {
  color: #f7f7f7 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d1d1d1 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #515184 !important;
}

.text-muted {
  color: #AEAED5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #E1E1F0;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #E1E1F0;
  }
}
* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.75;
}
body.fixed {
  overflow: hidden;
  position: fixed;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
}
a:hover, a:focus, a.active {
  text-decoration: none;
  outline: none;
  color: #1E74FD;
}

b,
strong,
.strong {
  font-weight: 700;
}

.h-0 {
  height: 0;
}

.main-wrap, .main-wrapper {
  opacity: 1;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.main-wrap.show, .main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

.text-grey-100 {
  color: #f8f9fa !important;
}

.text-grey-200 {
  color: #e9ecef !important;
}

.text-grey-300 {
  color: #dee2e6 !important;
}

.text-grey-400 {
  color: #ced4da !important;
}

.text-grey-500 {
  color: #adb5bd !important;
}

.text-grey-600 {
  color: #6c757d !important;
}

.text-grey-700 {
  color: #495057 !important;
}

.text-grey-800 {
  color: #343a40 !important;
}

.text-grey-900 {
  color: #212529 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.swatch-primary {
  background-color: #fff;
}

.swatch-secondary {
  background-color: #fff;
}

.swatch-success {
  background-color: #fff;
}

.swatch-info {
  background-color: #fff;
}

.swatch-warning {
  background-color: #212529;
}

.swatch-danger {
  background-color: #fff;
}

.swatch-light {
  background-color: #212529;
}

.swatch-dark {
  background-color: #fff;
}

.pt150 {
  padding-top: 150px;
}

.mont-font {
  font-family: "Montserrat", sans-serif;
}

.roboto-font {
  font-family: "Roboto", sans-serif;
}

.open-font {
  font-family: "Open Sans", sans-serif;
}

.fredoka-font {
  font-family: "Fredoka One", cursive;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../images/preloader.svg);
  background-size: 80px;
}

.navigation {
  position: fixed;
  top: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 280px;
  height: 100vh;
  overflow: hidden;
  background: #ffffff;
  -webkit-box-shadow: inset -1px 0 0 0 #e4e4e4;
  box-shadow: inset -1px 0 0 0 #e4e4e4;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;
  transition: width 0.25s;
  padding-top: 90px;
}
.navigation.menu-active {
  width: 90px;
}
.navigation.menu-active .nav-content {
  width: 90px;
}
.navigation.menu-active .nav-content ul li > a span {
  margin-left: 30px;
}
.navigation.menu-active .nav-content ul li > a span.circle-count,
.navigation.menu-active .card,
.navigation.menu-active .nav-top a .logo-text,
.navigation.menu-active .nav-caption span {
  display: none !important;
}
.navigation.menu-active .nav-caption {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}
.navigation.menu-current-color {
  background: linear-gradient(135deg, var(--theme-color), var(--theme-color-shade)) !important;
}
.navigation.menu-current-color .nav-top a .logo-text,
.navigation.menu-current-color .nav-content ul li:not(.logo) a i,
.navigation.menu-current-color .nav-content ul li:not(.logo) a span {
  color: #fff !important;
}
.navigation.menu-current-color .nav-caption {
  color: #fff !important;
  opacity: 0.6;
}
.navigation.menu-current-color .nav-content ul li > a.active {
  background: var(--theme-color-tint) !important;
}
@media (max-width: 991.98px) {
  .navigation {
    position: fixed;
    top: 0;
    left: -280px;
    z-index: 1000;
    height: 100vh;
    padding-top: 0;
    transition: all 0.4s ease;
  }
  .navigation.nav-active {
    left: 0;
  }
}
.navigation .card {
  transition: all 0.4s ease;
  width: calc(280px - 45px);
}
.navigation .nav-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 96px;
}
@media (max-width: 991.98px) {
  .navigation .nav-top {
    position: relative;
  }
}
.navigation .nav-top a {
  line-height: 75px;
  width: 280px;
  text-align: left;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  white-space: nowrap;
}
.navigation .nav-top a .logo-text {
  line-height: 75px;
  transition: all 0.4s ease;
}
.navigation .nav-caption {
  margin-bottom: 15px;
  padding-left: 35px;
  white-space: nowrap;
  color: #808191;
  -webkit-transition: padding 0.25s;
  -o-transition: padding 0.25s;
  transition: padding 0.25s;
}
.navigation .nav-content {
  width: 280px;
  transition: all 0.2s ease;
}
@media (max-width: 991.98px) {
  .navigation .nav-content {
    width: auto;
  }
}
@media (min-width: 992px) {
  .navigation .nav-content {
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
    border-radius: 0 10px 10px 0;
  }
}
.navigation .nav-content ul {
  padding: 0;
  margin: 0;
}
@media (min-width: 992px) {
  .navigation .nav-content ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .navigation .nav-content ul li.flex-lg-brackets {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
.navigation .nav-content ul li:not(.logo) {
  list-style-image: none;
  list-style-type: none;
  margin: 1px 15px;
}
@media (max-width: 991.98px) {
  .navigation .nav-content ul li:not(.logo) {
    margin: 4px 15px;
  }
}
.navigation .nav-content ul li:not(.logo) a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 5px;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #888;
  height: 48px;
  display: flex;
  overflow: hidden;
  transition: all 0.2s ease;
}
.navigation .nav-content ul li:not(.logo) a span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #888;
  transition: all 0.2s ease;
  margin-right: auto;
  padding-right: 10px;
}
.navigation .nav-content ul li:not(.logo) a i {
  font-size: 26px;
  color: var(--theme-color);
  top: 0px;
  position: relative;
}
.navigation .nav-content ul li:not(.logo) a .circle-icon {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  padding: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  top: 6px;
  right: 13px;
}
.navigation .nav-content ul li:not(.logo) a .circle-count {
  padding: 1px 6px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  top: 6px;
  display: inline-block;
  color: #fff;
  font-size: 13px;
  right: 13px;
}
.navigation .nav-content ul li.brackets {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.navigation .nav-content ul li > a.active {
}
.navigation .nav-content ul li > a.active span {
  color: #fff;
}

.navigation .nav-content ul li > a:hover span {
  color: var(--theme-color);
}
.navigation .nav-content ul li > a.active:hover span {
  color: #fff !important;
}

.app-footer {
  display: none;
}
@media (max-width: 991.98px) {
  .app-footer {
    min-height: 56px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: env(safe-area-inset-bottom);
  }
  .app-footer .cart-count {
    position: absolute;
    top: 2px;
    right: 10px;
    border-radius: 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
  }
  .app-footer .dot-count {
    position: absolute;
    top: 5px;
    right: 15px;
    border-radius: 5px;
    width: 7px;
    height: 7px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
  }
  .app-footer a {
    padding: 6px 20px;
  }
  .app-footer a i {
    color: var(--theme-color);
    font-size: 25px;
    top: 3px;
    position: relative;
  }
  .app-footer a span {
    color: var(--theme-color);
  }
}

.app-header-search {
  display: block;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #FFF;
  position: fixed;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
  left: 0;
  top: -50%;
  right: 0;
  width: 100%;
  z-index: 1001;
  transition: 0.2s all ease-in-out;
}
.app-header-search.show {
  top: env(safe-area-inset-top);
  transition: 0.3s all;
}

.live-stream {
  height: 600px;
}
@media (max-width: 1399.98) {
  .live-stream {
    height: 500px;
  }
}
@media (max-width: 1199.98px) {
  .live-stream {
    height: 500px;
  }
}
@media (max-width: 991.98px) {
  .live-stream {
    height: 400px;
  }
}

.main-content {
  background-color: #fbfcfe;
}
@media (min-width: 992px) {
  .main-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-left: 280px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
}
.main-content.menu-active {
  padding-left: 90px;
}
.main-content.menu-active .middle-sidebar-header.sticky-header {
  left: 90px;
}
@media (max-width: 991.98px) {
  .main-content {
    order: 1;
    position: relative;
    height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.main-content .left-sidebar, .main-content .right-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.main-content .left-sidebar .left-sidebar-content, .main-content .right-sidebar .left-sidebar-content {
  background: var(--theme-color-shade);
  overflow: hidden;
  width: 270px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0px 10px 10px 10px;
}
.main-content .left-sidebar .left-sidebar-content .left-sidebar-header, .main-content .right-sidebar .left-sidebar-content .left-sidebar-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 13px 15px 0 15px;
  background: var(--theme-color-shade);
}
@media (max-width: 767.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-header, .main-content .right-sidebar .left-sidebar-content .left-sidebar-header {
    padding: 20px 20px 0 20px;
  }
}
.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
  background: var(--theme-color-shade);
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 20px 15px 15px 15px;
}
@media (max-width: 1399.98) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    width: 400px;
  }
}
@media (max-width: 991.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    padding: 0 20px 0 20px;
  }
}
.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-title a i, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-title a i {
  position: relative;
  top: 3px;
}
.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul {
  padding-left: 31px;
}
.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul li a, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul li a {
  font-size: 12px;
}
.main-content .middle-sidebar-header {
  top: 0;
  left: 280px;
  right: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 96px;
  padding: 0 15px;
  background: #ffffff;
  border-bottom: 2px #eee solid;
  -webkit-transition: left 0.25s;
  -o-transition: left 0.25s;
  transition: left 0.25s;
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header {
    padding: 10px 15px 10px 15px;
    height: auto;
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
  }
}
.main-content .middle-sidebar-header .header-menu {
  display: none;
  border: 0;
  background-color: transparent;
  flex-shrink: 0;
  width: 32px;
  height: 38px;
  margin-right: 32px;
  font-size: 0;
}
.main-content .middle-sidebar-header .header-menu:focus {
  outline: none;
}
.main-content .middle-sidebar-header .header-menu:before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 2px;
  margin: 3px auto;
  border-radius: 1px;
  background: #1B1D21;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.main-content .middle-sidebar-header .header-menu:after {
  content: "";
  display: inline-block;
  width: 32px;
  height: 2px;
  margin: 3px auto;
  border-radius: 1px;
  background: #1B1D21;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.main-content .middle-sidebar-header .header-menu.active:before {
  transform: translateY(4px) rotate(45deg);
}
.main-content .middle-sidebar-header .header-menu.active:after {
  transform: translateY(-4px) rotate(-45deg);
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .header-menu {
    display: inline-block;
  }
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .header-search {
    display: none;
  }
}
.main-content .middle-sidebar-header .header-search .form-group.icon-input i {
  top: 10px;
}
.main-content .middle-sidebar-header .right-menu-icon li {
  margin-left: 25px;
}
.main-content .middle-sidebar-header .right-menu-icon li a {
  display: inline-block;
  position: relative;
}
.main-content .middle-sidebar-header .right-menu-icon li a .dot-count {
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}
.main-content .middle-sidebar-header .right-menu-icon li a:hover .menu-dropdown {
  visibility: visible;
  opacity: 1;
}
.main-content .middle-sidebar-header .right-menu-icon li a .menu-dropdown {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  width: 300px;
  padding: 25px 30px;
  box-shadow: 0px 15px 125px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  z-index: 999;
  border: 0;
  position: absolute;
  top: 120%;
  right: -5px;
  text-align: left;
  transition: all 0.4s ease;
}
.main-content .middle-sidebar-header .right-menu-icon li a .menu-dropdown:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -10px;
  right: 10px;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .right-menu-icon li {
    display: none;
  }
}
.main-content .middle-sidebar-header .right-menu-icon li:last-child {
  display: none;
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-header .right-menu-icon li:last-child {
    display: inline-block;
  }
}
.main-content .middle-sidebar-bottom {
  width: 100%;
  padding: 20px 20px 10px 20px;
}
@media (min-width: 992px) {
  .main-content .middle-sidebar-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}
@media (max-width: 1399.98) {
  .main-content .middle-sidebar-bottom {
    width: 400px;
  }
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-bottom {
    width: 100%;
    padding: 76px 15px 15px;
  }
}
.main-content .middle-sidebar-bottom .middle-sidebar-left {
  width: calc(100% - 320px);
}
.main-content .middle-sidebar-bottom .middle-sidebar-right {
  z-index: 2;
  overflow-x: hidden;
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-bottom .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 76px 15px 56px;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .main-content .middle-sidebar-bottom .middle-sidebar-right.active-sidebar {
    right: 0px;
    width: 100%;
  }
}
.main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content {
  padding: 0 2px 0 0;
  width: 320px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 0px;
}
.main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content .card {
  border: 1px #eee solid !important;
}
@media (max-width: 991.98px) {
  .main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content {
    margin-left: auto;
  }
}
.main-content .middle-sidebar-bottom .sidebar-right {
  background-color: #1a73e9;
  position: fixed;
  top: 47%;
  right: -27px;
  transform: translateY(-47%);
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  z-index: 222;
  visibility: hidden;
  opacity: 0;
}
.main-content .middle-sidebar-bottom .sidebar-right i {
  font-size: 18px;
  position: relative;
  left: -10px;
}

body:not(.theme-full) .main-content .middle-sidebar-header.sticky-header {
  position: fixed;
}

body:not(.theme-full) .main-content .middle-sidebar-bottom.header-padding {
  padding-top: 116px;
}

.accordion-toggle {
  position: relative;
  display: block;
  line-height: 25px;
}
.accordion-toggle.icon-none:after {
  display: none;
}
.accordion-toggle:after {
  font-family: "themify";
  position: absolute;
  content: "";
  right: -10px;
  top: 1px;
  color: #999;
  font-weight: lighter;
  transform: rotate(-180deg);
  transition: all 0.2s ease-in;
}
.accordion-toggle.collapsed::after {
  color: #999;
  transform: rotate(0deg);
  transition: all 0.2s ease-in;
}

.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  display: none;
  height: 40px;
}
@media only screen and (max-width: 992px) {
  .navbar-toggler {
    display: inline-block;
  }
}

.navbar-toggler-icon {
  width: 27px;
  height: 2px;
  background-color: #999;
  position: relative;
  top: -2px;
}
.navbar-toggler-icon:after {
  content: "";
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  top: -8px;
  left: 0;
}
.navbar-toggler-icon:before {
  content: "";
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.icon-count {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -10px;
  right: -10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.upper-header {
  background-color: #f9f9f9 !important;
}
.upper-header ul {
  margin-bottom: 0;
  margin-top: 20px;
}
.upper-header ul li {
  position: relative;
  padding-left: 15px;
}
.upper-header ul li a {
  color: #ddd;
  font-size: 11px;
  line-height: 38px;
  font-weight: 600;
  text-transform: uppercase;
}
.upper-header ul li a i {
  position: relative;
  top: 2px;
}
.upper-header.bg-greylight ul li a {
  color: #999;
}

.header-wrapper {
  width: 100%;
}
.header-wrapper.pos-fixed {
  position: fixed;
  top: 0;
  z-index: 5;
}
@media only screen and (max-width: 992px) {
  .header-wrapper.pos-fixed {
    position: relative;
  }
}
@media only screen and (max-width: 768px) {
  .header-wrapper.pos-fixed .navbar-collapse {
    background-color: #fff;
    padding: 0 10px;
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08) !important;
  }
}
.header-wrapper.shadow-xs {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
}
.header-wrapper .nav-menu {
  display: inline-block;
  float: left;
  margin-left: 40px;
  margin-top: 0px;
}
@media only screen and (max-width: 992px) {
  .header-wrapper .nav-menu {
    margin-left: 0;
    width: 100%;
    display: block;
  }
}
.header-wrapper .nav-menu.dropdown-menu-full {
  position: relative;
}
.header-wrapper .nav-menu.dropdown-menu-full li {
  position: initial;
}
.header-wrapper .nav-menu.text-white li a {
  color: #fff !important;
}
.header-wrapper .nav-menu.text-black li a {
  color: #000 !important;
}
.header-wrapper .nav-menu li {
  padding: 0 15px;
  display: inline-block;
  float: left;
}
@media only screen and (max-width: 992px) {
  .header-wrapper .nav-menu li {
    padding: 15px 0;
    text-align: left;
    width: 100%;
    margin: 0;
  }
}
.header-wrapper .nav-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}
.header-wrapper .nav-menu li .dropdown-menu {
  position: absolute;
  display: block;
  left: 20px;
  top: 148%;
  border: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.08) !important;
  min-width: 13rem;
  border-top: 3px var(--theme-color) solid;
  border-radius: 0 0 5px 5px;
  opacity: 0;
  visibility: hidden;
  z-index: 1030;
  transition: all 0.2s ease-in-out;
}
@media only screen and (max-width: 992px) {
  .header-wrapper .nav-menu li .dropdown-menu {
    position: relative;
    left: 0;
    top: 0;
    display: none;
    visibility: visible;
    opacity: 1;
    border-top: 0 solid #000;
    box-shadow: none !important;
    background-color: #f5f5f5;
    padding: 0;
    margin-top: 15px;
  }
  .header-wrapper .nav-menu li .dropdown-menu.show {
    display: block;
  }
}
.header-wrapper .nav-menu li .dropdown-menu a {
  padding: 12px 15px;
  font-size: 14px;
}
.header-wrapper .nav-menu li .dropdown-menu.full-wrap {
  min-width: 100%;
  top: 115%;
  left: 0;
  width: 100%;
  padding: 30px 30px 25px 40px;
  background-position: right 0px top 0;
  background-repeat: no-repeat;
  background-size: contain;
}
.header-wrapper .nav-menu li .dropdown-menu.full-wrap ul.sub-menu {
  padding-left: 0;
  margin-left: 0;
  width: 100%;
  float: left;
}
.header-wrapper .nav-menu li .dropdown-menu.full-wrap ul.sub-menu li {
  width: 100%;
  float: left;
  padding-left: 0;
}
.header-wrapper .nav-menu li .dropdown-menu.full-wrap ul.sub-menu li a {
  padding-left: 0;
  font-weight: 400;
  line-height: 14px;
  display: block;
  font-size: 14px;
}
.header-wrapper .nav-menu li a {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #777;
  transition: all 0.4s ease;
}
.header-wrapper .nav-menu li a i {
  font-size: 10px;
  margin-left: 5px;
}
@media only screen and (max-width: 992px) {
  .header-wrapper .nav-menu li a i {
    float: right;
    line-height: 22px;
    margin-right: 10px;
  }
}
.header-wrapper .nav-menu li a:after {
  display: none;
}
.header-wrapper .nav-menu li a:hover {
  color: var(--theme-color);
}
@media only screen and (max-width: 992px) {
  .header-wrapper .nav-menu li a {
    padding: 0;
    color: #777 !important;
  }
}
.header-wrapper .nav-menu.bg-dark li .nav-link {
  color: #eee !important;
  font-weight: 500;
}

.menu-icon {
  position: relative;
}
.menu-icon .dot-count {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px;
}

.footer-wrapper {
  margin-top: 100px;
  padding: 80px 0 10px;
  background-color: #f6f7fc;
}
.footer-wrapper.bg-dark h5 {
  color: #fff !important;
}
.footer-wrapper.bg-dark .middle-footer {
  border-top: 1px #555 solid !important;
}
.footer-wrapper.bg-dark p a {
  color: #ddd !important;
}
.footer-wrapper.bg-transparent {
  z-index: 100;
  position: fixed;
  left: 0;
  padding: 15px 80px;
  bottom: 0;
  margin-top: 0;
  width: 100%;
  box-shadow: none !important;
}
@media only screen and (max-width: 992px) {
  .footer-wrapper.bg-transparent {
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 768px) {
  .footer-wrapper {
    margin-top: 30px;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .footer-wrapper .logo img {
    width: 100px;
  }
}
@media only screen and (max-width: 576px) {
  .footer-wrapper {
    padding-bottom: 15px;
  }
}
.footer-wrapper .icon-img {
  height: 50px;
}
@media only screen and (max-width: 576px) {
  .footer-wrapper .icon-img {
    margin-bottom: 20px;
  }
}
.footer-wrapper form {
  overflow: hidden;
  border-radius: 2px;
}
.footer-wrapper form input {
  line-height: 50px;
  width: 70%;
  background-color: #fff;
  border: 0;
  outline: none;
  display: inline-block;
  padding: 0 15px;
  float: left;
  font-size: 14px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.footer-wrapper form button {
  text-transform: uppercase;
  float: left;
  width: 30%;
  line-height: 50px;
  text-align: center;
  background-color: var(--theme-color);
  border: 0;
  outline: none;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 768px) {
  .footer-wrapper .copyright-text {
    text-align: center;
    width: 100%;
    margin-bottom: 0px;
    line-height: 22px;
  }
}
.footer-wrapper h4 {
  font-size: 24px;
  line-height: 48px;
  font-weight: 300;
  color: #2f2d52;
  margin-bottom: 0;
}
@media only screen and (max-width: 768px) {
  .footer-wrapper h4 {
    font-size: 22px !important;
    line-height: 36px;
  }
}
.footer-wrapper h4 a {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  display: block;
  text-decoration: underline;
  margin-top: 20px;
}
.footer-wrapper p {
  color: #aaa;
  width: 60%;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
}
.footer-wrapper p a {
  color: #333;
  font-weight: 600;
}
.footer-wrapper h5 {
  font-weight: 600;
  color: #333;
  font-size: 13px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}
.footer-wrapper ul {
  margin-bottom: 0;
  margin-top: 20px;
}
.footer-wrapper ul li a {
  color: #aaa;
  font-size: 12px;
  line-height: 34px;
  font-weight: 500;
}
.footer-wrapper .middle-footer {
  padding-top: 75px;
  margin-top: 75px;
  border-top: 1px #ecedf2 solid;
}
@media only screen and (max-width: 768px) {
  .footer-wrapper .middle-footer {
    padding-top: 30px !important;
    margin-top: 20px !important;
    padding-bottom: 0;
  }
}
.footer-wrapper .lower-footer {
  padding-top: 50px;
}
@media only screen and (max-width: 768px) {
  .footer-wrapper .lower-footer {
    padding-top: 0;
  }
}

.accordion {
  margin-bottom: 50px;
}
.accordion .card {
  box-shadow: none;
  background: #fff;
  margin-bottom: 25px;
  border: 0;
}
.accordion .card .card-body,
.accordion .card .card-header {
  background: #fff;
  padding: 20px 30px;
  border-bottom: 0 solid #000;
}
@media only screen and (max-width: 768px) {
  .accordion .card .card-body,
.accordion .card .card-header {
    padding: 15px 40px 15px 15px;
  }
}
.accordion .card button {
  font-size: 15px;
  color: #111;
  font-weight: 600;
  padding-left: 0;
}
.accordion .card button:focus, .accordion .card button:hover {
  text-decoration: none;
}
.accordion .card button.small-text {
  font-size: 16px;
}
.accordion .card button.small-text a {
  color: #111;
}
.accordion .card button i {
  font-size: 30px;
  margin-right: 10px;
  position: relative;
  top: 32px;
  margin-right: 10px;
  color: #333;
  position: absolute;
  left: 33px;
}
.accordion .card button span {
  font-size: 11px;
  color: #999;
  font-weight: 400;
  display: block;
}
.accordion .card button:after {
  font-family: "themify";
  content: "";
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 23px;
  color: #888;
  transform: rotate(223deg);
  transition: all 0.5s ease;
}
@media only screen and (max-width: 768px) {
  .accordion .card button:after {
    right: 10px;
  }
}
.accordion .card button.small-text:after {
  top: 33px;
}
.accordion .card button.collapsed:after {
  transform: rotate(0deg);
  transition: all 0.5s ease;
}
.accordion .card p {
  font-size: 15px;
  line-height: 36px;
  color: #666;
}

.owl-carousel.overflow-visible .owl-stage-outer {
  overflow: visible;
}

.owl-carousel.overflow-visible .owl-item {
  opacity: 0.2;
  transition: all 0.4s ease;
}

.owl-carousel.overflow-visible.opacity-5 .owl-item {
  opacity: 0.5;
  transition: all 0.4s ease;
}

.owl-carousel.overflow-visible .owl-item.active {
  opacity: 1;
}

.owl-carousel.owl-theme .owl-dots {
  position: absolute;
  bottom: -70px;
  width: 100%;
}

.owl-carousel.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 4px;
  transition: all 0.4s ease;
}

.owl-carousel.owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--theme-color);
}

.owl-carousel.owl-theme.dot-none .owl-dots {
  display: none;
}

.owl-theme.owl-nav-link .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0%;
  margin-top: 0;
}

.owl-theme.owl-nav-link .owl-nav .owl-prev {
  left: 10px;
  top: 0;
  margin-top: -20px;
  position: absolute;
}

.owl-theme.owl-nav-link .owl-nav .owl-next {
  right: 10px;
  top: 0;
  margin-top: -20px;
  position: absolute;
}

.owl-theme.owl-nav-link .owl-nav [class*=owl-]:focus,
.owl-theme.owl-nav-link .owl-nav [class*=owl-]:hover {
  background-color: transparent !important;
  outline: none !important;
}

.owl-theme.owl-nav-link .owl-nav .icon-nav {
  font-size: 18px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #000;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
}

.owl-theme.owl-nav-link .owl-nav .icon-nav:hover {
  color: #000;
}

.owl-theme.owl-nav-link.edge-link .owl-nav .icon-nav {
  font-size: 18px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #000;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03) !important;
}

.owl-theme.owl-nav-link.edge-link .owl-nav {
  width: 100%;
  left: 0;
}

.owl-theme.owl-nav-link.edge-link .owl-nav .owl-next {
  right: 0px;
}

.owl-theme.owl-nav-link.edge-link .owl-nav .owl-prev {
  left: 0px;
}

.owl-theme.owl-nav-link.edge-link .owl-nav .icon-nav {
  box-shadow: none !important;
  margin-top: -10px;
}

.owl-theme.nav-none .owl-nav {
  display: none;
}

.owl-theme.dot-style2 .owl-dots {
  bottom: 20px !important;
}
.owl-theme.dot-style2 .owl-dots .active span {
  background-color: transparent !important;
  border: 1px #fff solid;
}
.owl-theme.dot-style2 .owl-dots span {
  width: 25px !important;
  height: 25px !important;
  padding: 10px;
  background-color: transparent;
  position: relative;
}
.owl-theme.dot-style2 .owl-dots span:after {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #fff;
  top: 9px;
  left: 9px;
}

.owl-theme.dot-style3.text-left-dot .owl-dots {
  text-align: center;
}
.owl-theme.dot-style3 .owl-dots {
  bottom: 0px !important;
  left: -4px !important;
  text-align: left;
}
.owl-theme.dot-style3 .owl-dots .active span {
  background-color: transparent !important;
  border: 2px var(--theme-color) solid;
}
.owl-theme.dot-style3 .owl-dots span {
  border: 2px transparent solid;
  transition: all 0.4s ease;
  width: 25px !important;
  height: 25px !important;
  padding: 10px;
  background-color: transparent;
  position: relative;
}
.owl-theme.dot-style3 .owl-dots span:after {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  content: "";
  position: absolute;
  display: inline-block;
  background-color: var(--theme-color);
  top: 9px;
  left: 9px;
}

.owl-theme.right-nav .owl-nav {
  position: absolute;
  right: 0;
  top: -90px;
  margin-top: 0;
}
.owl-theme.right-nav .owl-nav button {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 2px solid var(--theme-color);
}
.owl-theme.right-nav .owl-nav button i {
  color: var(--theme-color);
  font-size: 18px;
  line-height: 38px;
}
.owl-theme.right-nav .owl-nav button:hover, .owl-theme.right-nav .owl-nav button:focus {
  background-color: transparent !important;
  outline: none !important;
}
.owl-theme.right-nav .owl-nav button:hover i, .owl-theme.right-nav .owl-nav button:focus i {
  color: var(--theme-color);
}

.product-slider-3 .owl-thumbs {
  text-align: center;
  display: table;
  width: 80px;
  left: -90px;
  top: 0;
  margin-top: 0px;
  position: absolute;
}
.product-slider-3 .owl-thumb-item {
  width: 100%;
  height: auto;
  border: none;
  background: none;
  padding: 0;
  opacity: 0.5;
  overflow: hidden;
  background-color: #fff;
}
.product-slider-3 .owl-thumb-item img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.product-slider-3 .owl-thumb-item.active {
  opacity: 1;
}
.product-slider-3 .owl-thumb-item.active img {
  position: relative;
}
.product-slider-3 .owl-thumb-item:active, .product-slider-3 .owl-thumb-item:focus {
  outline: none !important;
}

.product-slider-4 .owl-thumbs {
  text-align: center;
  display: table;
  margin-top: 10px;
  width: 105%;
  margin-left: -10px;
  margin-right: -10px;
}
.product-slider-4 .owl-thumb-item {
  width: 46%;
  margin: 2% 2% 2% 2%;
  height: auto;
  border: none;
  background: none;
  padding: 0;
  opacity: 1;
  overflow: hidden;
  background-color: #fff;
}
.product-slider-4 .owl-thumb-item img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.product-slider-4 .owl-thumb-item.active {
  opacity: 1;
}
.product-slider-4 .owl-thumb-item.active img {
  position: relative;
}
.product-slider-4 .owl-thumb-item:active, .product-slider-4 .owl-thumb-item:focus {
  outline: none !important;
}

.banner-wrap {
  background-position: right 50px top 10px;
  background-color: #e4f7fe;
}
@media (max-width: 991.98px) {
  .banner-wrap {
    background-position: right -150px center;
    background-size: 80%;
  }
}
@media (max-width: 767.98px) {
  .banner-wrap {
    background-position: top 0px left;
  }
}

.modal-backdrop-none .modal-backdrop.show {
  opacity: 0 !important;
}

.modal .modal-content .close {
  width: 24px;
  height: 24px;
  border-radius: 20px;
  display: inline-block;
  line-height: 10px;
}
.modal .modal-content .close i {
  color: #fff;
  font-size: 16px;
}

.modal.in .modal-dialog {
  transform: none;
}

.modal.in {
  opacity: 1 !important;
}

.modal-backdrop.in {
  opacity: 0.5;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -280px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -280px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.bottom.fade .modal-dialog {
  bottom: -50%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog,
.modal.left.fade.show .modal-dialog {
  left: 0;
}

.modal.bottom.fade.in .modal-dialog,
.modal.bottom.fade.show .modal-dialog {
  bottom: 0px;
}

.modal.right.fade.in .modal-dialog,
.modal.right.fade.show .modal-dialog {
  right: 0px;
}

.modal-open .modal {
  overflow-y: hidden;
}

@media (min-width: 576px) {
  .modal.wide.fade .modal-dialog {
    max-width: 800px;
  }

  .modal.wide-xl.fade .modal-dialog {
    max-width: 1000px;
  }

  .modal.side.fade .modal-dialog {
    max-width: 400px;
  }

  .modal-dialog.video-wrap {
    max-width: 900px;
  }
  .modal-dialog.video-wrap .close {
    right: -20px;
    top: -20px;
  }
}
.toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-sizing: border-box;
  align-self: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
}
.toggle input[type=checkbox] {
  display: none;
}
.toggle .toggle-icon {
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: relative;
  transition: 0.3s;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  width: calc(40px);
  height: calc(20px + 2px);
  border-radius: 20px;
  background: #eee;
}
.toggle .toggle-icon:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(20px - 4px);
  width: calc(20px - 4px);
  border-radius: 18px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1;
  transition-duration: 0.3s;
  transform: scale(1);
}
.toggle .toggle-icon:after {
  height: calc(20px - 4px);
  width: calc(20px - 4px);
  top: 2px;
  left: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: calc(20px - 4px);
  background: #fff;
  position: absolute;
  z-index: 2;
  transform: translateX(0px);
  transition-duration: 0.3s;
  content: "";
}

.toggle input[type=checkbox]:checked + .toggle-icon {
  background: #ff3b30;
}

.toggle input[type=checkbox]:checked + .toggle-icon:after {
  transform: translateX(20px);
}

.toggle input[type=checkbox]:checked + .toggle-icon:before {
  opacity: 0;
}

.switchcolor-wrap ul {
  overflow: hidden;
}
.switchcolor-wrap ul li {
  display: inline-block;
  float: left;
  margin-right: 8px;
}
.switchcolor-wrap ul li .item-content {
  display: inline;
  padding-left: 0;
  position: relative;
}
.switchcolor-wrap ul li .item-content .ti-check {
  left: 10px;
  top: 3px !important;
}
.switchcolor-wrap ul li .item-content .ti-check:before {
  color: #fff;
  opacity: 0;
}
.switchcolor-wrap .circle-color {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
}
.switchcolor-wrap label.item-radio input[type=radio] {
  display: none;
}
.switchcolor-wrap label.item-radio input[type=radio]:checked ~ .ti-check:before {
  opacity: 1;
}
.switchcolor-wrap label.item-radio input[type=radio] ~ .ti-check {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: calc(var(--f7-safe-area-right) + 10px);
}
.switchcolor-wrap .toggle-div {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  border: 1px #eee solid;
}
.switchcolor-wrap .sheet-close {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #444;
}
.switchcolor-wrap .sheet-close i {
  font-size: 20px;
}

.dropdown-menu-settings {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  width: 300px;
  padding: 25px 30px;
  box-shadow: 0px 15px 125px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  z-index: 999;
  border: 0;
  position: absolute;
  top: 100%;
  right: 0;
  text-align: left;
  transition: all 0.4s ease;
}
.dropdown-menu-settings.active {
  visibility: visible;
  opacity: 1;
}
.dropdown-menu-settings:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -10px;
  right: 10px;
  display: inline-block;
}

.timer {
  display: block;
  position: relative;
  float: left;
}
.timer.style3 .time-count {
  max-width: 25%;
  width: 25%;
  margin: 0;
  padding: 0px;
  text-align: left;
}
.timer.style3 .time-count .text-time {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-left: 0 !important;
  margin-right: 20px !important;
  margin-bottom: 0 !important;
  text-align: left;
  background: transparent;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
@media only screen and (max-width: 992px) {
  .timer.style3 .time-count .text-time {
    font-size: 30px;
  }
}
.timer.style3 .time-count .text-day {
  text-align: left;
  margin-left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #eee;
  margin-top: 0 !important;
  margin-bottom: 10px;
}
.timer.bg-white .time-count {
  max-width: 25%;
  width: 25%;
  margin: 0;
  padding: 10px;
}
.timer.bg-white .time-count .text-time {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding-bottom: 0 !important;
}
.timer.bg-white .time-count .text-day {
  text-align: center;
  margin-left: 0;
  font-size: 14px;
  font-weight: 700;
  color: #999;
  margin-top: 0 !important;
  margin-bottom: 10px;
}
.timer.bg-grey-time .text-time {
  background-color: #f5f5f5;
}
.timer .time-count {
  margin: 8px;
  max-width: 60px;
  text-align: center;
  float: left;
  margin-left: 0;
}
.timer .text-time {
  padding: 12px;
  margin: 5px;
  font-weight: 600;
  font-size: 15px;
  background-color: #fff;
  border-radius: 5px;
}
.timer .text-day {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 6px;
}

.form-group.icon-input {
  position: relative;
}
.form-group.icon-input i {
  position: absolute;
  left: 15px;
  top: 19px;
}
.form-group.icon-input input {
  padding-left: 35px;
}
.form-group.icon-right-input {
  position: relative;
}
.form-group.icon-right-input i {
  position: absolute;
  right: 15px;
  top: 12px;
}
.form-group.icon-right-input input {
  padding-left: 35px;
}
.form-group .style1-input {
  height: 55px;
  line-height: 55px;
  display: block;
  font-size: 12px;
}
.form-group .style1-select {
  line-height: 55px;
  height: 55px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
}
.form-group .style2-select {
  line-height: 60px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
  border: 2px #eee solid;
  border-radius: 7px;
}
.form-group .style2-input {
  height: 60px;
  line-height: 60px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px;
}
.form-group .style2-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}
.form-group .style2-input::-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}
.form-group .style2-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}
.form-group .style2-input:-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}
.form-group .style2-input i {
  position: relative;
  top: 6px;
}
.form-group .style2-textarea {
  line-height: 20px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px;
}

.search-form {
  position: relative;
}
.search-form input {
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500;
}
.search-form i {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #999;
}

.form-control {
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  display: inline-block;
  border: 2px #eee solid;
}
.form-control:focus {
  border-color: var(--theme-color);
}
.form-control.style2 {
  height: 60px;
  line-height: 60px;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  vertical-align: middle;
  padding: 10px;
}
.input-file + .js-labelFile.btn-tertiary {
  color: #555;
  padding: 0;
  line-height: 40px;
  width: 300px;
  margin: auto;
  display: block;
  border: 2px solid #555;
}
.input-file + .js-labelFile.btn-tertiary:hover, .input-file + .js-labelFile.btn-tertiary:focus {
  color: #888888;
  border-color: #888888;
}
.input-file + .js-labelFile i {
  font-size: 24px;
  position: relative;
  top: 5px;
  display: inline-block;
}
.input-file + .js-labelFile i.large-icon {
  font-size: 42px;
}
.input-file + .js-labelFile.has-file i {
  display: none;
}

.subscribe-widget:after {
  background-image: url(../images/paper-plane.png);
  background-size: 200px;
  position: absolute;
  top: 10px;
  right: -45%;
  opacity: 0.3;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  width: 368px;
  height: 258px;
  z-index: -1;
}

.quantity {
  height: 60px;
  float: left;
  width: 100px;
  position: relative;
  border: 1px solid #DEDEDE;
}

.quantity-input {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-left: 17px;
  line-height: 60px;
  color: inherit;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.quantity .qtybutton {
  background-color: transparent;
  z-index: 1;
  position: absolute;
  height: 58px;
  cursor: pointer;
  width: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  top: 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.quantity .qtybutton.inc {
  right: 0;
}

.quantity .qtybutton.dec {
  left: 0;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 1px 0 rgba(0, 0, 0, 0.05);
}
.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: var(---theme-color);
  border: 0;
}
.custom-switch .custom-control-input:active ~ .custom-control-label::before {
  border: 0;
}

.custom-control-input:checked ~ .custom-control-label.bg-success::before {
  background-color: #10d876 !important;
  border-color: #10d876 !important;
}

.bar-container {
  position: relative;
  background-color: #eee;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 5px;
  margin-top: 7px;
  width: 100%;
  float: left;
}

.bar-percentage {
  background-color: #aaa;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 5px;
  padding: 0;
  width: 0;
  transition: all 0.4s ease;
}

select.sort {
  border: 0;
  color: #999;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px;
}

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 3px;
  border-radius: 10px;
}

.noUi-horizontal .noUi-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -3px;
  top: -3px;
  background-color: #222;
}

/* Styling;
 */
.noUi-background {
  background: #ddd;
}

.noUi-connect {
  background: #555;
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin,
.noUi-target {
  border-radius: 2px;
}

.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important;
}

.noUi-handle:active {
  border: 8px solid #345DBB;
  border: 8px solid rgba(53, 93, 187, 0.38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px;
}

.custom-radio.size-sm label::before {
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: -30px;
}

.quantity {
  height: 60px;
  float: left;
  width: 100px;
  position: relative;
  border: 1px solid #DEDEDE;
}

.quantity-input {
  border: 0;
  background: none;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-left: 17px;
  line-height: 60px;
  color: inherit;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.quantity .qtybutton {
  background-color: transparent;
  z-index: 1;
  position: absolute;
  height: 58px;
  cursor: pointer;
  width: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  top: 0;
  text-align: center;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.quantity .qtybutton.inc {
  right: 0;
}

.quantity .qtybutton.dec {
  left: 0;
}

.cart-wrapper .quantity {
  height: 45px;
}

.cart-wrapper .quantity-input {
  line-height: 45px;
  font-size: 15px;
}

.cart-wrapper .quantity .qtybutton {
  height: 43px;
  line-height: 43px;
}

.contact-wrap {
  z-index: 1;
  top: -100px;
  padding: 60px 80px 70px 80px;
}
@media (max-width: 1199.98px) {
  .contact-wrap {
    padding: 50px;
  }
}
@media (max-width: 991.98px) {
  .contact-wrap {
    padding: 40px 30px;
  }
  .contact-wrap .display3-size {
    font-size: 40px !important;
    margin-bottom: 30px !important;
  }
}

.contact-wrapper {
  padding: 80px;
  max-width: 550px;
}
@media (max-width: 1199.98px) {
  .contact-wrapper {
    padding: 50px;
    max-width: 500px;
    margin-top: 80px;
    margin-bottom: 50px;
  }
}

.video-btn {
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  position: absolute;
  top: 60px;
  left: 50%;
  margin-left: -30px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
}
.video-btn i {
  line-height: 60px;
  font-size: 21px;
}

.video-bttn {
  position: relative;
}
.video-bttn:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-left: -30px;
  margin-top: -30px;
  background-image: url(../images/icon-play.png);
  background-size: contain;
  display: block;
  width: 60px;
  height: 60px;
}
.video-bttn.default {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50% !important;
  animation: videobtn 1.9s linear infinite;
  display: inline-block;
  width: 60px;
  height: 60px;
}
.video-bttn.default:after {
  margin-left: 0;
  position: relative;
  left: 0;
}

.question-div {
  transition: all 0.4s ease;
}
.question-div .question-ans {
  cursor: pointer;
  transition: all 0.4s ease;
}
.question-div .question-ans.style2 {
  border: 2px solid transparent !important;
}
.question-div .question-ans.style1.active {
  background-color: var(--theme-color) !important;
  color: #fff !important;
}
.question-div .question-ans.style2.active {
  border: 2px solid var(--theme-color) !important;
}
.question-div .question-ans.style3 span {
  transition: all 0.4s ease;
}
.question-div .question-ans.style3.active span {
  background-color: var(--theme-color) !important;
  color: #fff !important;
}

.triangle-after {
  position: relative;
}
.triangle-after:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #fff transparent transparent transparent;
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 2;
}

.modal-popup-body,
.chat-wrapper,
.chat-left {
  height: calc(100vh - 150px);
}
.modal-popup-body::-webkit-scrollbar-track,
.chat-wrapper::-webkit-scrollbar-track,
.chat-left::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.modal-popup-body::-webkit-scrollbar,
.chat-wrapper::-webkit-scrollbar,
.chat-left::-webkit-scrollbar {
  width: 4px;
  background-color: #f1f1f1;
}
.modal-popup-body::-webkit-scrollbar-thumb,
.chat-wrapper::-webkit-scrollbar-thumb,
.chat-left::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

.arrow-right:after {
  background-image: url(../images/arrow.png);
  width: 50px;
  right: -30px;
  z-index: 2;
  position: absolute;
  height: 17px;
  background-size: contain;
  background-position: center center;
  display: inline-block;
  content: "";
  position: absolute;
  top: 35px;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 768px) {
  .arrow-right:after {
    display: none;
  }
}

.nav-tabs li a {
  transition: all 0.4s ease;
}
.nav-tabs li a.active {
  border-bottom: 2px #333 solid !important;
  color: #000 !important;
}

.login-card {
  min-width: 380px;
  max-width: 400px;
}

.coming-soon-card {
  min-width: 500px;
  max-width: 500px;
}

.banner-slider .style-div {
  height: 570px;
}
@media (max-width: 1199.98px) {
  .banner-slider .style-div {
    height: 450px;
  }
}

.recent-post {
  padding-left: 20px;
}
.recent-post li {
  list-style-type: disc;
  margin-bottom: 5px;
}
.recent-post li a {
  font-size: 14px;
  font-weight: 500;
  color: #777;
}

.next-article:before {
  display: block !important;
  content: "";
  width: 100%;
  height: 2px;
  top: 0px;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to right, #FFF 0, #eeeeee 35%, #eeeeee 65%, #FFF 100%);
  background: -ms-linear-gradient(left, #fff 0, #eeeeee 35%, #eeeeee 65%, #fff 100%);
  background: -o-linear-gradient(left, #fff 0, #eeeeee 35%, #eeeeee 65%, #fff 100%);
  background: -webkit-linear-gradient(left, #FFF 0, #eeeeee 35%, #eeeeee 65%, #FFF 100%);
  background: -moz-linear-gradient(left, #fff 0, #eeeeee 35%, #eeeeee 65%, #fff 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #FFF), color-stop(35%, #eeeeee), color-stop(65%, #eeeeee), color-stop(100%, #FFF));
}

.next-article:after {
  display: block !important;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0px;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to right, #FFF 0, #eeeeee 35%, #eeeeee 65%, #FFF 100%);
  background: -ms-linear-gradient(left, #fff 0, #eeeeee 35%, #eeeeee 65%, #fff 100%);
  background: -o-linear-gradient(left, #fff 0, #eeeeee 35%, #eeeeee 65%, #fff 100%);
  background: -webkit-linear-gradient(left, #FFF 0, #eeeeee 35%, #eeeeee 65%, #FFF 100%);
  background: -moz-linear-gradient(left, #fff 0, #eeeeee 35%, #eeeeee 65%, #fff 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #FFF), color-stop(35%, #eeeeee), color-stop(65%, #eeeeee), color-stop(100%, #FFF));
}

.modal-popup-chat {
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
}
.modal-popup-chat .modal-popup-wrap {
  width: 350px;
}
.modal-popup-chat .modal-popup-wrap .modal-popup-header {
  top: 0;
  left: 0;
}
.modal-popup-chat .modal-popup-wrap .modal-popup-body {
  padding-top: 80px;
  padding-bottom: 80px;
}
.modal-popup-chat .modal-popup-wrap .modal-popup-footer {
  bottom: 0;
  left: 0;
}
.modal-popup-chat .message-content {
  color: #594939;
  padding: 10px 20px;
  background-color: #fcf6ee;
  border-radius: 20px 20px 20px 0px;
  max-width: 80%;
  display: inline-block;
  text-align: left;
}
.modal-popup-chat .message.self .message-content {
  border-radius: 20px 20px 0px 20px;
  background-color: #e2efff;
  color: #2A4E7F;
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: var(---theme-color);
  color: #9880ff;
  box-shadow: none;
  animation: dotTyping 1.5s infinite linear;
  top: -4px;
  position: relative;
}

.list-group .list-group-item {
  border-bottom: 1px solid #eee;
  position: relative;
}
.list-group .list-group-item.p-0:after {
  content: "";
  position: absolute;
  color: #ddd;
  font-family: "themify";
  right: -5px;
  top: 0px;
  font-size: 11px;
}
.list-group .list-group-item.no-icon:after {
  display: none;
}
.list-group .list-group-item .badge {
  position: absolute;
  right: 0;
  top: 18px;
}
.list-group .list-group-item span {
  font-size: 12px;
  color: #999;
  margin-top: 3px;
}
.list-group .list-group-item.style2 {
  padding: 12px;
}
.list-group .list-group-item.style2 i {
  width: auto;
  height: auto;
  color: #555;
  line-height: 24px;
}

.memberlist {
  margin-left: -15px;
  position: relative;
}
.memberlist li {
  display: inline-block;
  float: none;
  position: relative;
  width: 15px;
}
.memberlist li.last-member a {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 30px;
  line-height: 30px;
}

.chatlist .item {
  width: 55px;
}
.chatlist .item img {
  width: 100%;
  border: 2px var(--theme-color) solid;
  border-radius: 50px;
  padding: 2px;
}
.chatlist .item.dashed img {
  border: 2px var(--theme-color) dashed;
}
.chatlist .item.online:after {
  width: 10px;
  height: 10px;
  background-color: #10d876;
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: inline-block;
  z-index: 2;
  content: "";
  border-radius: 5px;
}
.chatlist.style2 .item {
  width: 55px;
  border: 2px solid transparent;
}
.chatlist.style2 .item.no-ouline {
  border: 2px #999 solid;
  border-radius: 22px;
  text-align: center;
  line-height: 52px;
}
.chatlist.style2 .item.no-ouline:before {
  content: none;
}
.chatlist.style2 .item.no-ouline span {
  line-height: 20px;
  width: 110%;
  left: -6%;
  margin-top: 2px;
}
.chatlist.style2 .item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: 0px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(to right, red, orange);
  border-radius: 22px !important;
}
.chatlist.style2 .item img {
  border: 2px #000 solid;
  margin: 0px;
  border-radius: 22px !important;
}
.chatlist.style2 .item.online:after {
  bottom: 2px;
  right: 2px;
}
.chatlist.style2 .item span {
  position: absolute;
  width: 100%;
  margin-top: 3px;
  text-align: center;
}

.chat-bottom {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11;
}

.chat-form {
  padding: 4px;
  width: 100%;
}
.chat-form .form-group {
  width: calc(100% - 90px);
  border-radius: 30px;
  float: left;
  margin: 0 5px;
  position: relative;
}
.chat-form .form-group:after {
  background-color: var(--theme-color);
  opacity: 0.08;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
}
.chat-form input {
  width: 100%;
  border: 0;
  border-radius: 30px;
  font-size: 14px;
  padding: 0 15px;
  line-height: 40px;
  color: #ddd;
  font-weight: 500;
}
.chat-form button {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.chat-form button i {
  font-size: 16px;
}

.chat-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0px 0 0;
}
@media (max-width: 991.98px) {
  .chat-body {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-right: 20px;
    padding-left: 20px;
    overflow: auto !important;
  }
}
.chat-body .messages-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 70px !important;
  height: 800px;
}
.chat-body .messages-content .message-item.outgoing-message {
  margin-left: auto;
  margin-right: 5px;
}
.chat-body .messages-content .message-item.outgoing-message .message-user {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.chat-body .messages-content .message-item.outgoing-message .message-wrap {
  background-color: var(--theme-color);
  color: #fff;
}
.chat-body .messages-content .message-item {
  max-width: 97%;
  margin-bottom: 20px;
}
@media (max-width: 1399.98) {
  .chat-body .messages-content .message-item {
    max-width: 75%;
  }
}
.chat-body .messages-content .message-item .message-wrap {
  padding: 14px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
  font-size: 13px;
  color: #000;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  z-index: 2;
}
.chat-body .messages-content .message-item .message-wrap:after {
  background-color: var(--theme-color);
  opacity: 0.1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 1;
}
.chat-body .messages-content .message-item .message-wrap.outgoing-message::after {
  display: none;
}
@media (max-width: 1399.98) {
  .chat-body .messages-content .message-item .message-wrap {
    padding: 20px;
  }
}
.chat-body .messages-content .message-item .message-user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}
.chat-body .messages-content .message-item .message-user .avatar {
  top: 3px;
  position: relative;
}
.chat-body .messages-content .message-item .message-user .avatar img {
  display: inline-block;
  margin-bottom: 0;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
}
.chat-body .messages-content .message-item .message-user h5 {
  font-size: 14px;
  color: #0c0c0d;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0px;
}
.chat-body .messages-content .message-item .message-user .time {
  font-size: 11px;
  font-weight: 500;
  color: #aaaaaa;
  margin-bottom: 0px;
  position: relative;
  top: -3px;
  right: auto;
  display: inline-block;
}
.chat-body .messages-content .message-item .message-user .time .ti-double-check:before {
  content: "";
  font-family: themify;
  position: relative;
  left: 9px;
}
.chat-body .messages-content .message-item .message-user .time .ti-double-check:after {
  content: "";
  font-family: themify;
}

.cart-wrapper .table tbody td {
  border-top: 0;
  vertical-align: middle;
  padding-top: 10px;
}

.input-code {
  width: 200px;
  height: 60px;
  line-height: 60px;
}
@media (max-width: 991.98px) {
  .input-code {
    width: 165px;
  }
}

.animation-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
}
.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@keyframes videobtn {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.2), 0 0 0 30px rgba(255, 255, 255, 0.2), 0 0 0 50px rgba(255, 255, 255, 0);
  }
}
@keyframes videobtn_red {
  0% {
    box-shadow: 0 0 0 0 rgba(236, 57, 139, 0.1), 0 0 0 10px rgba(236, 57, 139, 0.2), 0 0 0 50px rgba(236, 57, 139, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(236, 57, 139, 0.2), 0 0 0 50px rgba(236, 57, 139, 0.2), 0 0 0 100px rgba(236, 57, 139, 0);
  }
}
@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 var(--theme-color), 9995px 0 0 0 var(--theme-color), 10005px 0 0 0 var(--theme-color);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 var(--theme-color), 9995px 0 0 0 var(--theme-color), 10005px 0 0 0 var(--theme-color);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 var(--theme-color), 9995px 0 0 0 var(--theme-color), 10005px 0 0 0 var(--theme-color);
  }
  50% {
    box-shadow: 9984px 0 0 0 var(--theme-color), 9995px -10px 0 0 var(--theme-color), 10005px 0 0 0 var(--theme-color);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 var(--theme-color), 9995px 0 0 0 var(--theme-color), 10005px 0 0 0 var(--theme-color);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 var(--theme-color), 9995px 0 0 0 var(--theme-color), 10005px -10px 0 0 var(--theme-color);
  }
  100% {
    box-shadow: 9984px 0 0 0 var(--theme-color), 9995px 0 0 0 var(--theme-color), 10005px 0 0 0 var(--theme-color);
  }
}
.right-scroll-bar {
  margin-left: 0px;
  overflow-y: scroll;
}

.right-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent;
}

.right-scroll-bar::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}

.right-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

.scroll-bar {
  margin-left: 0px;
  overflow-y: scroll;
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.scroll-bar::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

.email-message a {
  margin-bottom: 3px;
  padding: 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  position: relative;
}
@media (max-width: 991.98px) {
  .email-message a {
    padding: 20px 10px;
    height: auto;
    display: block;
  }
}
.email-message a .form-check {
  display: inline-block;
  position: relative;
}
@media (max-width: 991.98px) {
  .email-message a .form-check {
    display: none;
  }
}
.email-message a .email-user {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 226px;
  flex: 0 0 226px;
  width: 226px;
  padding: 0 20px 0 10px;
}
.email-message a .email-user img, .email-message a .email-user span.btn-round-sm {
  margin-left: 7px;
  margin-right: 7px;
}
@media (max-width: 991.98px) {
  .email-message a .email-user .btn-round-xss {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .email-message a .email-user h6 {
    margin-left: 4px;
  }
}
@media (max-width: 1199.98px) {
  .email-message a .email-user {
    flex: 0 0 180px;
    width: 180px;
    padding: 0 20px 0 0px;
  }
}
@media (max-width: 991.98px) {
  .email-message a .email-user {
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0;
  }
}
.email-message a .email-subject {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 210px;
  flex: 0 0 210px;
  width: 210px;
  padding: 0 20px 0 34px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  color: #44444F;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991.98px) {
  .email-message a .email-subject {
    display: none;
  }
}
.email-message a .email-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 552px);
  flex: 0 0 calc(100% - 552px);
  max-width: calc(100% - 552px);
  padding-right: 20px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #92929D;
}
@media (max-width: 991.98px) {
  .email-message a .email-text {
    max-width: 100%;
    height: 46px;
    margin-top: -10px;
    padding: 0 0 0 47px;
    white-space: normal;
    line-height: 1.66667;
  }
}
.email-message a .email-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0;
}
@media (max-width: 991.98px) {
  .email-message a .email-file {
    display: inline-block;
    position: absolute;
    top: 50px;
    left: 10px;
    width: auto;
  }
}
.email-message a .email-time {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 72px;
  flex: 0 0 72px;
  width: 72px;
  margin-left: auto;
  text-align: right;
  font-size: 12px;
  color: #696974;
}
@media (max-width: 991.98px) {
  .email-message a .email-time {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 15px;
    width: auto;
  }
}

.course-slider {
  height: 410px;
  background-color: #000;
}
@media (max-width: 1199.98px) {
  .course-slider {
    height: 370px;
  }
}
.course-slider .card-body {
  width: 60%;
  padding: 0px 0 0 70px;
  z-index: 3;
}
@media (max-width: 991.98px) {
  .course-slider .card-body {
    width: 90%;
    padding: 20px;
  }
}
@media (max-width: 991.98px) {
  .course-slider .card-body .w200 {
    width: 150px !important;
  }
}
.course-slider .video-wrap {
  width: 80%;
  margin-left: auto;
  position: relative;
  height: 100%;
}
@media (max-width: 991.98px) {
  .course-slider .video-wrap {
    width: 100%;
  }
}
.course-slider .video-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 60%;
  height: 100%;
  background: linear-gradient(to right, #000, transparent);
  z-index: 2;
}
.course-slider .video-wrap video {
  position: absolute;
  width: 120%;
  top: 0;
}
@media (max-width: 991.98px) {
  .course-slider .video-wrap video {
    width: 200%;
  }
}
.cat-card-hover {
  cursor: pointer;
}
.cat-card-hover img {
  transition: all 0.4s ease !important;
  position: relative;
}
.cat-card-hover:hover img {
  transform: scale(1.2);
}

.course-card .card-image {
  overflow: hidden;
}
.course-card .card-image img {
  transition: all 0.4s ease !important;
  position: relative;
}
.course-card:hover .card-image img {
  transform: scale(1.1);
}

@media (min-width: 991px) {
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
  }

  .middle-wrap {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .pr-md--2 {
    padding-right: 0.5rem !important;
  }

  .pl-md--2 {
    padding-left: 0.5rem !important;
  }

  .pt-lg--5 {
    padding-top: 30px !important;
  }

  .pb-lg--5 {
    padding-bottom: 30px !important;
  }

  .pt-lg--7 {
    padding-top: 75px !important;
  }

  .pb-lg--7 {
    padding-bottom: 75px !important;
  }

  .pt-lg--10 {
    padding-top: 125px !important;
  }

  .pb-lg--10 {
    padding-bottom: 125px !important;
  }

  .pl-md--0 {
    padding-left: 0;
  }

  .p-md--5 {
    padding: 3rem !important;
  }

  .float-right-md {
    float: right !important;
  }

  .vh-lg--100 {
    height: 100vh;
  }
}
@media (min-width: 768px) {
  .p-md--5 {
    padding: 3rem !important;
  }

  .vh-md-100 {
    height: 100vh;
  }
}
@media (min-width: 1800px) {
  .col-xxl-9 {
    flex: 0 0 73%;
    max-width: 73%;
  }

  .col-xxl-3 {
    flex: 0 0 27%;
    max-width: 27%;
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
@media (min-width: 1600px) {
  .container-half-fluid {
    width: 1400px;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .middle-sidebar-left {
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .vh-lg-100 {
    height: 100vh;
  }

  .pl-lg--5 {
    padding-left: 3rem !important;
  }

  .pr-lg--5 {
    padding-right: 3rem !important;
  }

  .p-lg--5 {
    padding: 3rem !important;
  }
}
@media (max-width: 1600px) {
  .display5-size {
    font-size: 60px !important;
  }

  .main-content .middle-sidebar-bottom .sidebar-right {
    visibility: visible;
    opacity: 1;
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-left {
    width: 100%;
  }

  .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 15px;
    overflow: scroll;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.3);
  }
  .middle-sidebar-right.active-sidebar {
    right: 0px;
  }
}
@media (max-width: 1400px) {
  .overflow-visible-xl,
.overflow-visible-xl .owl-stage-outer {
    overflow: visible !important;
  }

  .xl-p-5 {
    padding: 3rem;
  }
}
@media (max-width: 1200px) {
  .coming-soon-card, .login-card {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .display4-lg-size {
    font-size: 50px !important;
  }
}
@media (max-width: 991px) {
  .display2-md-size {
    font-size: 30px !important;
  }

  .display4-md-size {
    font-size: 40px !important;
  }

  .coming-soon-card, .login-card {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .md-mb-2 {
    margin-bottom: 1rem !important;
  }

  .main-content .middle-sidebar-bottom .sidebar-right {
    display: none;
  }
}
@media (max-width: 768px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sm-mb-4 {
    margin-bottom: 2rem !important;
  }

  .sm-mt-2 {
    margin-top: 1rem !important;
  }

  .sm-mt-7 {
    margin-top: 5rem !important;
  }

  .sm-mb-5 {
    margin-bottom: 3rem !important;
  }

  .font-md-xxl {
    font-size: 28px !important;
  }

  .sm-text-center {
    text-align: center;
  }

  .coming-soon-card,
.login-card {
    min-width: 320px;
  }

  .sm-pt-10 {
    padding-top: 5rem;
  }
}
@media (max-width: 576px) {
  .d-none-xs {
    display: none !important;
  }

  .xs-mb-4 {
    margin-bottom: 2rem !important;
  }

  .xs-mb-2 {
    margin-bottom: 1rem !important;
  }

  .xs-p-4 {
    padding: 2rem;
  }

  .xs-pt-10 {
    padding-top: 150px;
  }

  .owl-theme.owl-nav-link .owl-nav .icon-nav,
.owl-theme.nav-xs-none .owl-nav {
    display: none !important;
  }

  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .display4-xs-size {
    font-size: 40px !important;
  }

  .font-md-xs {
    font-size: 18px !important;
  }

  .display1-sm-size {
    font-size: 25px !important;
  }
}

